export const colors = {
    'primary': 'var(--primary)',
    'secondary': 'var(--secondary)',
    'black': 'var(--black)',
    'white': 'var(--white)',
    'text': 'var(--text)',
    'background': 'var(--background)',
    'spotlight': 'var(--spotlight)',
    'indigo-accent': 'var(--indigo-accent)',
    'orange-accent': 'var(--orange-accent)',
    'yellow-accent': 'var(--yellow-accent)',
    'green-accent': 'var(--green-accent)',
    'blue-accent': 'var(--blue-accent)',
    'success': 'var(--success)',
    'info': 'var(--info)',
    'warning': 'var(--warning)',
    'danger': 'var(--danger)',
    'light': 'var(--light)',
    'dark': 'var(--dark)',
    'border': 'var(--border)',
    'highlight': 'var(--highlight)',
}
