/**
 * Auth
 */
export const LOGIN = 'users/login'
export const LOGOUT = 'users/logout'
export const VERIFY_TOKEN = 'users/checktoken'

/**
 * Surveys
 */
export const SURVEY = 'survey'
